export const POLL_ORG_BALANCE_INTERVAL_MS = 30000;

export const INITIAL_CURRENT_USER_STATE_OBJ = {
    user: {},
    realUser: {},
    configParams: {},
    isSuper: false,
    isUAFSigned: false,
    canChangeOrg: false,
    canChangeStudy: false,
    showHighlight: false,
    isSSOEnabled: false,
};

export const ROUTES_THAT_DONT_REQUIRE_AUTH = [
    "/login",
    "/logout",
    "/password/reset",
    "/status403",
    "/status404",
];

export const USER_ROLES = {
    ADMIN_DASHBOARD: "Admin Dashboard",
    CUSTOM_VISUALIZATION: "Custom Visualization",
    ESTIMATE_PRICING: "Estimate Pricing",
    FULL_ACCESS: "Full Access",
    FULL_ACCESS_MINUS_DOWNLOAD_TRAVEL: "Full Access Minus Download Travel",
    MANAGE_TRAVEL_ZONES: "Manage Travel Zones",
    SMOOTHING_BYPASS_OPTION: "Upsampling Bypass Option",
    VIEW_AND_DOWNLOAD_ONLY: "View and Download Only",
    HELP_ACCESS_ONLY: "Help Access Only",
    INSIGHT_GO: "InSight Go",
    PROJECT_CREATOR: "Project Creator",
};
export const USER_ROLES_LIST = Object.values(USER_ROLES);

export const REDIRECT_KEY = "?redirect=";

export const REDIRECT_URL_KEY = "redirect-url";

export const ORG_ID_KEY = "?org_id=";
